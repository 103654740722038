<template>
    <div>
        <!-- Mobile Header -->
        <div class="wsmobileheader clearfix">
            <a id="wsnavtoggle" class="wsanimated-arrow"><span></span></a>
            <span class="smllogo"><img v-bind:src="`${baseUrl}assets/image/multimode.png`" width="120" alt="Multimode"/></span>
            <a href="tel:01710636667" class="callusbtn"><i class="fas fa-phone"></i></a>
        </div>
        <!-- Mobile Header -->

        <div class="headerfull">
            <div class="wsmain clearfix">
                <div class="smllogo">
                    <router-link to="/"><img v-bind:src="`${baseUrl}assets/image/multimode.png`" width="145" alt="Multimode"/></router-link>
                </div>
                <nav class="wsmenu clearfix">
                    <ul class="wsmenu-list">

                        <li class="border-right">
                            <router-link to="/" class="navtext closenav" exact><span>ড্যাশবোর্ড</span></router-link>
                        </li>

                        <li v-if="accessTypes.includes('2') || accessRole == 1" aria-haspopup="true" class="border-right">
                            <a href="javascript:void(0);" class="navtext"><span class="submenu-icon">সদস্য</span></a>
                            <ul class="sub-menu">
                                <li v-if="accessModules.includes('3') || accessRole == 1" class="border-bottom"><router-link to="/member/create" class="closenav">সদস্য ভর্তি</router-link></li>
                                <li v-if="accessModules.includes('4') || accessRole == 1"><router-link to="/member/lists" class="closenav">সদস্য তালিকা</router-link></li>
                            </ul>
                        </li>

                        <li v-if="accessTypes.includes('3') || accessRole == 1" aria-haspopup="true" class="border-right">
                            <a href="javascript:void(0);" class="navtext"><span class="submenu-icon">লেনদেন</span></a>
                            <div class="wsmegamenu clearfix halfmenu">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-12">
                                            <ul class="wstliststy06 clearfix">
                                                <li class="wstheading clearfix">উদ্যোক্তা শেয়ার</li>
                                                <li v-if="accessModules.includes('7') || accessRole == 1"><router-link to="/share/collection" class="closenav">উদ্যোক্তা শেয়ার গ্রহণ</router-link></li>
                                                <li v-if="accessModules.includes('8') || accessRole == 1"><router-link to="/share/withdraw" class="closenav">উদ্যোক্তা শেয়ার উত্তোলন</router-link></li>

                                                <li class="wstheading clearfix">প্রজেক্ট শেয়ার</li>
                                                <li v-if="accessModules.includes('16') || accessRole == 1"><router-link to="/fdr/collection" class="closenav">প্রজেক্ট শেয়ার গ্রহণ</router-link></li>
                                                <li v-if="accessModules.includes('17') || accessRole == 1"><router-link to="/fdr/withdraw" class="closenav">প্রজেক্ট শেয়ার উত্তোলন</router-link></li>
                                            </ul>
                                        </div>
                                        <div class="col-lg-6 col-md-12">
                                            <ul class="wstliststy06 clearfix">
                                                <li class="wstheading clearfix">ঋণ / বিনিয়োগ</li>
                                                <li v-if="accessModules.includes('11') || accessRole == 1"><router-link to="/loan/create" class="closenav">ঋণ প্রদান</router-link></li>
                                                <li v-if="accessModules.includes('12') || accessRole == 1"><router-link to="/installment/create" class="closenav">কিস্তি আদায়</router-link></li>

                                                <li class="wstheading clearfix">কর্জ হিসাব</li>
                                                <li v-if="accessModules.includes('20') || accessRole == 1"><router-link to="/borrow/collection" class="closenav">কর্জ গ্রহণ</router-link></li>
                                                <li v-if="accessModules.includes('21') || accessRole == 1"><router-link to="/borrow/withdraw" class="closenav">কর্জ পরিশোধ</router-link></li>

                                                <li class="wstheading clearfix">অন্যান্য</li>
                                                <li v-if="accessModules.includes('24') || accessRole == 1"><router-link to="/salary/create" class="closenav">বেতন-ভাতাদি প্রদান</router-link></li>
                                                <li v-if="accessModules.includes('26') || accessRole == 1"><router-link to="/particular-transaction/create" class="closenav">অন্যান্য লেনদেন</router-link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li v-if="accessTypes.includes('4') || accessRole == 1" aria-haspopup="true" class="border-right">
                            <a href="javascript:void(0);" class="navtext"><span class="submenu-icon">লেনদেনের তালিকা</span></a>
                            <div class="wsmegamenu clearfix halfmenu">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-12">
                                            <ul class="wstliststy06 clearfix">
                                                <li class="wstheading clearfix">উদ্যোক্তা শেয়ার</li>
                                                <li v-if="accessModules.includes('9') || accessRole == 1"><router-link to="/share/lists" class="closenav">উদ্যোক্তা শেয়ারের তালিকা</router-link></li>

                                                <li class="wstheading clearfix">প্রজেক্ট শেয়ার</li>
                                                <li v-if="accessModules.includes('18') || accessRole == 1"><router-link to="/fdr/lists" class="closenav">প্রজেক্ট শেয়ারের তালিকা</router-link></li>
                                            </ul>
                                        </div>
                                        <div class="col-lg-6 col-md-12">
                                            <ul class="wstliststy06 clearfix">
                                                <li class="wstheading clearfix">ঋণ / বিনিয়োগ</li>
                                                <li v-if="accessModules.includes('13') || accessRole == 1"><router-link to="/loan/lists" class="closenav">ঋণের / কিস্তির তালিকা</router-link></li>

                                                <li class="wstheading clearfix">কর্জ হিসাব</li>
                                                <li v-if="accessModules.includes('22') || accessRole == 1"><router-link to="/borrow/lists" class="closenav">কর্জের তালিকা</router-link></li>

                                                <li class="wstheading clearfix">অন্যান্য</li>
                                                <li v-if="accessModules.includes('25') || accessRole == 1"><router-link to="/salary/lists" class="closenav">বেতন-ভাতাদির তালিকা</router-link></li>
                                                <li v-if="accessModules.includes('27') || accessRole == 1"><router-link to="/particular-transaction/lists" class="closenav">অন্যান্য লেনদেনের তালিকা</router-link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li v-if="accessTypes.includes('5') || accessRole == 1" aria-haspopup="true" class="border-right">
                            <a href="javascript:void(0);" class="navtext"><span class="submenu-icon">আদায় ফর্ম</span></a>
                            <ul class="sub-menu">
                                <li v-if="accessModules.includes('66') || accessRole == 1" class="border-bottom"><router-link to="/collection/saving" class="closenav">সঞ্চয় আদায় ফর্ম</router-link></li>
                                <li v-if="accessModules.includes('29') || accessRole == 1" class="border-bottom"><router-link to="/collection/installment" class="closenav">কিস্তি আদায় ফর্ম</router-link></li>
                                <li v-if="accessModules.includes('30') || accessRole == 1" class="border-bottom"><router-link to="/collection/common" class="closenav">সকল আদায় ফর্ম</router-link></li>
                                <li v-if="accessModules.includes('65') || accessRole == 1" class="border-bottom"><router-link to="/collection/due-installment" class="closenav">বকেয়া ঋণের তালিকা</router-link></li>
                            </ul>
                        </li>

                        <li v-if="accessTypes.includes('6') || accessTypes.includes('7') || accessRole == 1" aria-haspopup="true" class="border-right">
                            <a href="javascript:void(0);" class="navtext"><span class="submenu-icon">রিপোর্ট</span></a>
                            <div class="wsmegamenu clearfix halfmenu">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div v-if="accessTypes.includes('6') || accessRole == 1" class="col-lg-6 col-md-12">
                                            <ul class="wstliststy06 clearfix">
                                                <li class="wstheading clearfix">জমা খরচ রিপোর্ট</li>
                                                <li v-if="accessModules.includes('31') || accessRole == 1"><router-link to="/report/collection" class="closenav">মাসিক জমার হিসাব</router-link></li>
                                                <li v-if="accessModules.includes('32') || accessRole == 1"><router-link to="/report/expenditure" class="closenav">মাসিক খরচের হিসাব</router-link></li>
                                                <li v-if="accessModules.includes('33') || accessRole == 1"><router-link to="/report/collection-expenditure" class="closenav">জমা খরচ হিসাব</router-link></li>
                                                <li v-if="accessModules.includes('34') || accessRole == 1"><router-link to="/report/transaction" class="closenav">লেনদেনের রিপোর্ট</router-link></li>
                                                <li v-if="accessModules.includes('35') || accessRole == 1"><router-link to="/report/tasks" class="closenav">নোটিফিকেশন রিপোর্ট</router-link></li>
                                            </ul>
                                        </div>
                                        <div v-if="accessTypes.includes('7') || accessRole == 1" class="col-lg-6 col-md-12">
                                            <ul class="wstliststy06 clearfix">
                                                <li class="wstheading clearfix">হিসাব প্রতিবেদন</li>
                                                <li v-if="accessModules.includes('36') || accessRole == 1"><router-link to="/accounts/general-ledger" class="closenav">সাধারণ খতিয়ান</router-link></li>
                                                <li v-if="accessModules.includes('37') || accessRole == 1"><router-link to="/accounts/trial-balance" class="closenav">রেওয়ামিল</router-link></li>
                                                <li v-if="accessModules.includes('38') || accessRole == 1"><router-link to="/accounts/profit-loss" class="closenav">লাভ-ক্ষতি ও বন্টন</router-link></li>
                                                <li v-if="accessModules.includes('39') || accessRole == 1"><router-link to="/accounts/balance-sheet" class="closenav">উদ্ধৃতপত্র</router-link></li>
                                                <li v-if="accessModules.includes('40') || accessRole == 1"><router-link to="/accounts/detail-lists" class="closenav">ডিটেল লিস্ট</router-link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li v-if="accessTypes.includes('8') || accessRole == 1" aria-haspopup="true" class="border-right">
                            <a href="javascript:void(0);" class="navtext"><span class="submenu-icon">এসএমএস</span></a>
                            <ul class="sub-menu">
                                <li v-if="accessModules.includes('41') || accessRole == 1" class="border-bottom"><router-link to="/sms/new" class="closenav">নতুন এসএমএস</router-link></li>
                                <li v-if="accessModules.includes('42') || accessRole == 1"><router-link to="/sms/lists" class="closenav">এসএমএস তালিকা</router-link></li>
                            </ul>
                        </li>

                        <li v-if="accessTypes.includes('9') || accessRole == 1" aria-haspopup="true" class="border-right">
                            <a href="javascript:void(0);" class="navtext"><span class="submenu-icon">অ্যাডমিন</span></a>
                            <ul class="sub-menu">
                                <li v-if="accessModules.includes('45') || accessRole == 1" class="border-bottom"><router-link to="/admin/lists" class="closenav">অ্যাডমিন তালিকা</router-link></li>
                                <li v-if="accessModules.includes('48') || accessRole == 1"><router-link to="/admin/permission" class="closenav">অ্যাডমিন কার্যক্ষমতা</router-link></li>
                            </ul>
                        </li>

                        <li v-if="accessTypes.includes('10') || accessRole == 1" aria-haspopup="true" class="border-right">
                            <a href="javascript:void(0);" class="navtext"><span class="submenu-icon">সেটিংস</span></a>
                            <div class="wsmegamenu clearfix halfmenu">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-12">
                                            <ul class="wstliststy06 clearfix">
                                                <li class="wstheading clearfix">সাধারণ সেটিংস</li>
                                                <li v-if="accessModules.includes('50') || accessRole == 1"><router-link to="/particular/lists" class="closenav">লেনদেনের খাতসমূহ</router-link></li>
                                                <li v-if="accessModules.includes('59') || accessRole == 1"><router-link to="/branch/lists" class="closenav">প্রকল্প সমূহ</router-link></li>
                                                <li v-if="accessModules.includes('53') || accessRole == 1"><router-link to="/group/lists" class="closenav">গ্রুপ সমূহ</router-link></li>
                                                <li v-if="accessModules.includes('56') || accessRole == 1"><router-link to="/center/lists" class="closenav">কেন্দ্র সমূহ</router-link></li>
                                            </ul>
                                        </div>
                                        <div class="col-lg-6 col-md-12">
                                            <ul class="wstliststy06 clearfix">
                                                <li class="wstheading clearfix">সফটওয়্যার সেটিংস</li>
                                                <li v-if="accessModules.includes('62') || accessRole == 1"><router-link to="/committee/lists" class="closenav">পরিচালনা পরিষদ</router-link></li>
                                                <li v-if="accessModules.includes('63') || accessRole == 1"><router-link to="/settings/view" class="closenav">সফটওয়্যার সেটিংস</router-link></li>
                                                <li><a href="https://wa.me/+8801710636667" class="closenav" target="_blank">যোগাযোগ</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li aria-haspopup="true" class="wsshopmyaccount border-left border-right">
                            <span class="wsmenu-click"><i class="wsmenu-arrow"></i></span>
                            <a href="javascript:void(0);"><i class="fa fa-chevron-circle-down"></i>
                                <span v-if="configData">{{configData.adminName}}</span>
                                <span v-else>আমার একাউন্ট</span>
                            </a>
                            <ul class="sub-menu">
                                <li>
                                    <router-link to="/admin/profile" class="closenav"><i class="fas fa-user-tie"></i>আমার প্রোফাইল</router-link>
                                </li>
                                <li>
                                    <router-link to="/admin/tasks" class="closenav"><i class="fas fa-tasks"></i>কার্যতালিকা</router-link>
                                </li>
                                <li>
                                    <router-link to="/admin/profile" class="closenav"><i class="fas fa-lock"></i>পাসওয়ার্ড পরিবর্তন</router-link>
                                </li>
                                <li>
                                    <a href="javascript:void(0)" class="closenav" @click="logout()"><i class="fas fa-sign-out-alt"></i>Logout</a>
                                </li>
                            </ul>
                        </li>

                    </ul>
                </nav>
            </div>
        </div>

        <div v-if="logoutLoader" class="loading-overlay">
            <span class="fas fa-spinner fa-3x fa-spin"></span>
        </div>

    </div>
</template>

<script>
    import Vue from 'vue'
    import {logoutFromApp, logoutFromBackend} from "../../services/auth";

    export default {
        name: 'Menu',
        components: {},
        data() {
            return {
                logoutLoader: false,
                configData: JSON.parse(localStorage.getItem("users")),
                accessRole: JSON.parse(localStorage.getItem("users")).role,
                accessTypes: JSON.parse(localStorage.getItem("users")).accessTypesId,
                accessModules: JSON.parse(localStorage.getItem("users")).accessModulesId
            };
        },
        mounted() {
        },
        methods: {
            logout() {

                this.logoutLoader = true;

                logoutFromBackend().then((response) => {
                    if (response.status) {
                        this.flashMessage.success({message: response.message});
                    } else {
                        this.flashMessage.error({message: response.message});
                    }
                }).catch((e) => {
                    this.flashMessage.error({message: e});
                });

                setTimeout(function () {
                    logoutFromApp();
                }, 1000);
            }
        }
    }
</script>
<style>
    .callusbtn {
        color: #a9a9a9;
        font-size: 18px;
        position: absolute;
        right: 5px;
        top: 0px;
        transition: all 0.4s ease-in-out 0s;
        z-index: 102;
        padding: 12px 14px;
    }

    /* overlay loading */
    .loading-overlay {
      display: flex;
      background: rgba(255, 255, 255, 0.7);
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      z-index: 9998;
      align-items: center;
      justify-content: center;
    }
    /* overlay loading */
</style>
